@mixin montserrat-50 {
  --size: 40px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: var(--size);
  color: var(--back);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
	@include from(m) {
    --size: 50px;
  }
}

@mixin montserrat-40 {
  --size: 30px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: var(--size);
  color: var(--back);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
	@include from(m) {
    --size: 40px;
  }
}

@mixin montserrat-30 {
  --size: 24px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: var(--size);
  color: var(--back);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
	@include from(m) {
    --size: 30px;
  }
}

@mixin montserrat-18 {
  --size: 16px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: 26px;
  color: var(--back);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
	@include from(m) {
    --size: 18px;
  }
}

@mixin montserrat-15 {
  --size: 15px;
	font-family: 'Montserrat', sans-serif !important;
  font-size: var(--size);
  line-height: 20px;
  color: var(--dark-color);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}

@mixin montserrat-14 {
  --size: 14px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: var(--size);
  color: var(--dark-color);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}

@mixin montserrat-9 {
  --size: 7px;
	font-family: 'Montserrat', sans-serif;
  font-size: var(--size);
  line-height: var(--size);
  &.sm-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}


// Mixin para las media queries.
@mixin from($bp) {
  @if map-has-key($breakPoints, $bp) {
      @media screen and(min-width: map-get($breakPoints, $bp)) {
          @content;
      }
  }@else {
      @media screen and(min-width: $bp) {
          @content;
      } 
  }
}

@mixin to($bp) {
  @if map-has-key($breakPoints, $bp) {
      @media screen and(max-width: map-get($breakPoints, $bp)) {
          @content;
      }
  }@else {
      @media screen and(max-width: $bp) {
          @content;
      } 
  }
}

// Sistema de margenes y padding top bottom left right de 5px a 200px
@for $i from 0 through 40 {
  .mxy-#{$i*5} {
		margin: #{$i * 5px} !important;
	}
	.mb-#{$i*5} {
		margin-bottom: #{$i * 5px} !important;
	}
	.mt-#{$i*5} {
		margin-top: #{$i * 5px} !important;
	}
	.mr-#{$i*5} {
		margin-right: #{$i * 5px} !important;
	}
	.ml-#{$i*5} {
		margin-left: #{$i * 5px} !important;
	}
  .pxy-#{$i*5} {
		padding: #{$i * 5px} !important;
	}
	.pb-#{$i*5} {
		padding-bottom: #{$i * 5px} !important;
	}
	.pt-#{$i*5} {
		padding-top: #{$i * 5px} !important;
	}
	.pr-#{$i*5} {
		padding-right: #{$i * 5px} !important;
	}
	.pl-#{$i*5} {
		padding-left: #{$i * 5px} !important;
	}
}

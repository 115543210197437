@import "../../../styles/mixin";
@import "../../../styles/variables"; 

.register_container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
}

.container-buttons-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

//Steps 2
.card-matter-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  p:hover {
    color: lighten($blue-second, 9)
  }
}.matters-grid {
  width: 100%;
  display: grid;
  gap: 1rem;

  @include from(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include from(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Steps 3
.grid-input {
  display: grid;
  gap: 1rem;
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.terms_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -2.5px !important;
  color: #1890ff;
  line-height: 1;
}


.price-table-item {
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem;
}
.table-item {
 
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem; 
    
    
  } 
  .table-item-selection {
    border: 0.5px solid #4C8170;
    width: auto;
    max-width: 300px;
    height: auto;
    background-color: white;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 30px;
    padding-bottom: 1rem;   
  }

.price-table-header {
  position: relative;
}

.table-header-image {
  width: 100%;
}

.table-header-content {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-table-list {
  background-color: transparent;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
      padding: .3rem 0;
      border-bottom: 1px solid var(--green-second);
    }
  }
}


  .boton-select{
    background-color: #0C4393 !important;
    color: white !important;
  }
  



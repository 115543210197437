@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-toolip {
  min-width: 250px;
  background-color: white;
  @include from(m) {
    width: auto;
  }

 

  display: grid;
  place-items: center;
  padding: 1rem;
  box-shadow: var(--shadow);
  position: absolute;
  z-index: var(--z-fixed);
  top: 3.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  border: 2px solid rgb(253, 252, 252);
  
  right: 4%;

  &.notification {
    min-height: 250px;
    right: -1.5rem;
    &:before {
      right: 2rem;
    }
  }

  &:before {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #0C4393;
    box-shadow: var(--shadow);
    position: absolute;
   
    z-index: var(--z-normal);
    width: 0;
    height: 0;
    top: -10px;
    right: .3rem;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
  }
}

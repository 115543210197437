@import "../../../styles/mixin";
@import "../../../styles/variables";

.page-footer_container {
  width: 100%;
  height: auto;
  background-color: var(--blue-primary);
}

.page-footer {
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem;
  background-color: var(--blue-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  gap: 1rem;
  @include from(720px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 1rem 5rem ;
  }
}

.page-footer_logo {
  img {
    cursor: pointer;
  }
  @include from(720px) {
    text-align: left;
  }
}

.page-footer_links {
  display: flex;
  text-align:start;
  flex-direction: column;
  margin-top: 1rem;
  color: var(--green-second);
  white-space: nowrap;
  @include from(420px){
    flex-direction: row;
  }
  p {
    padding: 0 1rem;
    &:not(:first-child) {
      @include from(420px){
        border-left: 1px solid var(--green-second);
      } 
    }
  }
}

.page_color_blue{
  background-color: $blue-primary;
}

.col-footer {
  flex-basis: 100%;
}

@media screen and (min-width: 840px) {
  .row_footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;    
    height: 50px;
  }

  .row_footer_contact {  
    height: 50px;
    
  }  
 
  .row_footer_p {
    height: 20px;
  }

  .col-footer {
    flex: 1;
  }
 
  
}

.page_footer_center {
  text-align: left;

}


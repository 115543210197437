@import "./fonts.css";
@import "./variables";
@import "./mixin";

// Normalización de los estilos
*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth !important;
  &::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    // display: none;  /* Ocultar scroll */
  }

  &::-webkit-scrollbar-thumb {
    background: var(--blue-second);
    border-radius: 4px;
  }  

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  &::-webkit-scrollbar-thumb:hover {
      background: var(--blue-second);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  &::-webkit-scrollbar-thumb:active {
    background-color:  var(--blue-second);
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  &::-webkit-scrollbar-track {
    background-color: var( --yellow);
    border-radius: 4px;
  }

  /* Cambiamos el fondo cuando esté en active o hover */
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background-color: var( --yellow);
  }
}


body {
  background-color: var(--green-light);
  font-family: 'Montserrat', sans-serif;
	margin: 0;
  color: var(--black);
  // padding-top: var(--headerHeight); // Se baja lo que mide el Header Fixed ya que perdio su flujo en el HTML
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 { 
  font-weight: 700;
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	margin-top: 0;
	li {
		list-style: none;
	}
}

.link {
  color: var(--blue-second);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: lighten($blue-second, 10);
  }
}

a {
	text-decoration: none;
	color: inherit;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-letf {
  text-align: left;
}

.text-ellipsis {
  width: auto;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;  /* Si no se declara añade los ... pero muestra todo el texto */
}


// Clases generales 

.form {
  width: 90%;
  background-color: white;
  padding: 2rem 1.5rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: var(--shadow);
  border-radius: 5px;
  @include from(m) {
    width: 100%;
    padding: 2rem 3rem;
  }
}

.box-white {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  box-shadow: var(--shadow);
  padding: 2rem 1.5rem;
  @include from(m) {
    padding: 2rem 3rem;
  }
}

.max-width {
  width: 100%;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

// Fuentes 
.montserrat-50 {
  @include montserrat-50;
}
.montserrat-40 {
  @include montserrat-40;
}
.montserrat-30 {
  @include montserrat-30;
}
.montserrat-18 {
  @include montserrat-18;
}
.montserrat-15 {
  @include montserrat-15;
}
.montserrat-14 {
  @include montserrat-14;
}
.montserrat-9 {
  @include montserrat-9;
}


// Colores fuentes

.c-blue-primary {
  color: var(--blue-primary);
}

.c-blue-second {
  color: var(--blue-second);
}

.c-yellow {
  color: var(--yellow);
}

.c-green-primary {
  color: var(--green-primary);
}
.c-green-second {
  color: var(--green-second);
}
.c-green-light {
  color: var(--green-light);
}
.c-green-bold {
  color: var(--green-bold);
}

.c-black {
  color: var(--black);
}

.c-black-light {
  color: lighten(#000, 45%);
}

.c-deg {
  color: var(--gradiente-three);
}

.c-white {
  color: white;
}



// Clases de antd - sobreescribir
button, .button { 
  border-radius: 40px;
  height: 37px ;
  padding: 0 2rem;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  outline: 0;
  border: 2px solid transparent;
  &.primary {
    background-color:#0C4393;
    color: white;
    &:hover {
      background-color: white;
      border: 2px solid #0C4393;
      color:#0C4393;
    }
  }
  &.blue-outline {
    background: transparent;
    border: 2px solid #0C4393;
    color: #0C4393;
    &:hover {
      background: var(--gradiente-one);
      border: 2px solid white;
      color: white ;
    }
  }
  &.yellow {
    background: var(--gradiente-five);
    border: 2px solid var(--yellow);
    color: #0C4393;
    &.h-white {
      &:hover {
        background: transparent;
        border: 2px solid white;
        color: white;
      }
    }
    &:hover {
      background: transparent;
      border: 2px solid var(--yellow);
    }
  } 
 
}

button.ant-btn {
  border-radius: 30px !important;
  height: 40px !important;
  padding-left: 1rem !important;
  padding-right : 1rem !important;
  background-color: transparent !important;
  color: #0C4393 !important;
  border-color: #0C4393 !important;
  &:hover {
    background-color: #0C4393 !important;
    color: white !important;
  }
  &.ant-btn-primary {
    background-color: #0C4393 !important;
    color: white !important;
    border-color: #0C4393 !important;
    &:hover {
      background-color: lighten($color: #204781, $amount: 10%) !important;
    }
  }
}

.ant-row {
  display: block !important;
}

.ant-card {
  border-radius: 5px !important; 
  box-shadow: var(--shadow) !important;
}

.ant-card-body {
  padding: 0 !important;
}

.ant-divider {
  color: var(--green-second) !important;
  background-color: var(--green-second) !important;
}

.ant-card-meta-title {
  width: 240px !important;
  @include from(m) {
    width: 380px !important;
  }
  @include from(l) {
    width: 400px !important;
  }
  @include from(xl) {
    width: 500px !important;
  }
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ant-card-head-title {
  font-size: 14px !important;
  flex: 1 1 0 !important;
  padding-right: .5rem !important;
}

.bg-light {
	background-color: var(--green-light) !important;
}


// Material ui, custom class
.MuiPaper-root {
  background-color: transparent !important;
  // margin-bottom: 1rem !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid var(--green-second) !important;
  outline: 0 !important;
  &::before {
    height: 0 !important;
  }
}


// React-carrousel custom class 
.control-arrow, .carousel-status {
  display: none !important;
}

.carousel .control-dots .dot {
  width: 15px !important;
  height: 15px !important;
  background-color: var(--yellow) !important;
}
.carousel .carousel-slider  {
  margin: 0 auto !important;
}


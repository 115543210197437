@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.header-toolip_user {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  img {
    width: 40px;
    height: 40px;
    @include from(m) {
      width: 50px;
      height: 50px;
    }
  }
}

.menu-button{
  background: #ffff;
}

.header-toolip_links {
  width: 100%;
  padding: 1rem 1rem 0 0;
}

.header-toolip_link {
  display: flex;
  align-items: center;
  p {
    cursor: pointer;
    &:hover {
      color: var(--blue-second);
    }
  }
}

.header-toolip_sesion {
  width: 100%;
  padding: 1rem 1rem 0 0;
  p {
    cursor: pointer;
    &:hover {
      color: var(--blue-second);
    }
  }
}

.toolip-divider {
  width: 100%;
  border-bottom: 1px solid var(--green-light);
}

@import "../../../styles/mixin";
@import "../../../styles/variables"; 

.register_container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  gap: 2rem;
  padding: 2rem;
}

.container-buttons-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

//Steps 2
.card-matter-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  p:hover {
    color: lighten($blue-second, 9)
  }
}.matters-grid {
  width: 100%;
  display: grid;
  gap: 1rem;

  @include from(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include from(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.react-tel-input .form-control{
  width: 100% !important;
  border-radius: 1px !important;
  height: 40.33px !important;
  padding-top: 6.5px !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant: tabular-nums !important;  
  line-height: 1.5715 !important;  
  font-feature-settings: 'tnum', "tnum" !important;
}

.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

// Steps 3
.grid-input {
  display: grid;
  gap: 1rem;
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}


.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  color: #1890ff;
  line-height: 1;
  
}

.table-item {
 
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem; 
    
    
  } 
  .table-item-selection {
    border: 0.5px solid #4C8170;
    width: auto;
    max-width: 300px;
    height: auto;
    background-color: white;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 30px;
    padding-bottom: 1rem;   
  }

  .gray{
    background-color: #7677788a !important;
    color: white !important;
    border-color: #4c4d4e8a !important;
  }

  

  q::before{
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }

  
    @media screen and (min-width: 840px) {
    .container-buttons-register {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:  nowrap !important;
    }

    .ant-steps-item-icon .ant-steps-icon {
      position: relative;
      color: #1890ff;
      line-height: 1;
      top: -0.5px !important;
    }
    
    
  }

  
